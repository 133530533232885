import { default as password_45recoverySvA5sDa9rEMeta } from "/vercel/path0/pages/auth/password-recovery.vue?macro=true";
import { default as password_45reset1WIJaoCAKRMeta } from "/vercel/path0/pages/auth/password-reset.vue?macro=true";
import { default as signinBiAH3O7TaSMeta } from "/vercel/path0/pages/auth/signin.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as couponyTys1HjdouMeta } from "/vercel/path0/pages/coupon.vue?macro=true";
import { default as _91id_93x1dmlqCaqZMeta } from "/vercel/path0/pages/dashboard/admin/business/detail/[id].vue?macro=true";
import { default as new_45admissionsPU4HAuPPMxMeta } from "/vercel/path0/pages/dashboard/admin/business/new-admissions.vue?macro=true";
import { default as published_45listQuUAi2EfEcMeta } from "/vercel/path0/pages/dashboard/admin/business/published-list.vue?macro=true";
import { default as _91id_93ShlaFQAmhAMeta } from "/vercel/path0/pages/dashboard/admin/coupon/detail/[id].vue?macro=true";
import { default as listlwnALmlxJCMeta } from "/vercel/path0/pages/dashboard/admin/coupon/list.vue?macro=true";
import { default as _91id_931QiwHLCbEyMeta } from "/vercel/path0/pages/dashboard/business-admin/business/edit/[id].vue?macro=true";
import { default as _91id_93Z4cWhuArYOMeta } from "/vercel/path0/pages/dashboard/business-admin/coupon/detail/[id].vue?macro=true";
import { default as _91id_93wkTSDF4X64Meta } from "/vercel/path0/pages/dashboard/business-admin/coupon/edit/[id].vue?macro=true";
import { default as listtbLEWrQH38Meta } from "/vercel/path0/pages/dashboard/business-admin/coupon/list.vue?macro=true";
import { default as newVIw6LUw36bMeta } from "/vercel/path0/pages/dashboard/business-admin/coupon/new.vue?macro=true";
import { default as redeemtyrNlToC5uMeta } from "/vercel/path0/pages/dashboard/business-admin/coupon/redeem.vue?macro=true";
import { default as _91id_93AsX1Aa2UIxMeta } from "/vercel/path0/pages/dashboard/business-admin/coupon/validate/[id].vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as profile9R2UgaCjXxMeta } from "/vercel/path0/pages/dashboard/profile.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_934Sh6rbj1csMeta } from "/vercel/path0/pages/web/comercio/[id].vue?macro=true";
import { default as _91id_93kAm74EoMDgMeta } from "/vercel/path0/pages/web/cupon/version-imprimible/[id].vue?macro=true";
import { default as ppJgK2NRIUsZMeta } from "/vercel/path0/pages/web/pp.vue?macro=true";
import { default as registro_45comerciodvnWHRICCEMeta } from "/vercel/path0/pages/web/registro-comercio.vue?macro=true";
export default [
  {
    name: password_45recoverySvA5sDa9rEMeta?.name ?? "auth-password-recovery",
    path: password_45recoverySvA5sDa9rEMeta?.path ?? "/auth/password-recovery",
    meta: password_45recoverySvA5sDa9rEMeta || {},
    alias: password_45recoverySvA5sDa9rEMeta?.alias || [],
    redirect: password_45recoverySvA5sDa9rEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/password-recovery.vue").then(m => m.default || m)
  },
  {
    name: password_45reset1WIJaoCAKRMeta?.name ?? "auth-password-reset",
    path: password_45reset1WIJaoCAKRMeta?.path ?? "/auth/password-reset",
    meta: password_45reset1WIJaoCAKRMeta || {},
    alias: password_45reset1WIJaoCAKRMeta?.alias || [],
    redirect: password_45reset1WIJaoCAKRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/password-reset.vue").then(m => m.default || m)
  },
  {
    name: signinBiAH3O7TaSMeta?.name ?? "auth-signin",
    path: signinBiAH3O7TaSMeta?.path ?? "/auth/signin",
    meta: signinBiAH3O7TaSMeta || {},
    alias: signinBiAH3O7TaSMeta?.alias || [],
    redirect: signinBiAH3O7TaSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: confirm2uAIxw9fKZMeta?.name ?? "confirm",
    path: confirm2uAIxw9fKZMeta?.path ?? "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    alias: confirm2uAIxw9fKZMeta?.alias || [],
    redirect: confirm2uAIxw9fKZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: couponyTys1HjdouMeta?.name ?? "coupon",
    path: couponyTys1HjdouMeta?.path ?? "/coupon",
    meta: couponyTys1HjdouMeta || {},
    alias: couponyTys1HjdouMeta?.alias || [],
    redirect: couponyTys1HjdouMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/coupon.vue").then(m => m.default || m)
  },
  {
    name: _91id_93x1dmlqCaqZMeta?.name ?? "dashboard-admin-business-detail-id",
    path: _91id_93x1dmlqCaqZMeta?.path ?? "/dashboard/admin/business/detail/:id()",
    meta: _91id_93x1dmlqCaqZMeta || {},
    alias: _91id_93x1dmlqCaqZMeta?.alias || [],
    redirect: _91id_93x1dmlqCaqZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/admin/business/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: new_45admissionsPU4HAuPPMxMeta?.name ?? "dashboard-admin-business-new-admissions",
    path: new_45admissionsPU4HAuPPMxMeta?.path ?? "/dashboard/admin/business/new-admissions",
    meta: new_45admissionsPU4HAuPPMxMeta || {},
    alias: new_45admissionsPU4HAuPPMxMeta?.alias || [],
    redirect: new_45admissionsPU4HAuPPMxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/admin/business/new-admissions.vue").then(m => m.default || m)
  },
  {
    name: published_45listQuUAi2EfEcMeta?.name ?? "dashboard-admin-business-published-list",
    path: published_45listQuUAi2EfEcMeta?.path ?? "/dashboard/admin/business/published-list",
    meta: published_45listQuUAi2EfEcMeta || {},
    alias: published_45listQuUAi2EfEcMeta?.alias || [],
    redirect: published_45listQuUAi2EfEcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/admin/business/published-list.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ShlaFQAmhAMeta?.name ?? "dashboard-admin-coupon-detail-id",
    path: _91id_93ShlaFQAmhAMeta?.path ?? "/dashboard/admin/coupon/detail/:id()",
    meta: _91id_93ShlaFQAmhAMeta || {},
    alias: _91id_93ShlaFQAmhAMeta?.alias || [],
    redirect: _91id_93ShlaFQAmhAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/admin/coupon/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: listlwnALmlxJCMeta?.name ?? "dashboard-admin-coupon-list",
    path: listlwnALmlxJCMeta?.path ?? "/dashboard/admin/coupon/list",
    meta: listlwnALmlxJCMeta || {},
    alias: listlwnALmlxJCMeta?.alias || [],
    redirect: listlwnALmlxJCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/admin/coupon/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_931QiwHLCbEyMeta?.name ?? "dashboard-business-admin-business-edit-id",
    path: _91id_931QiwHLCbEyMeta?.path ?? "/dashboard/business-admin/business/edit/:id()",
    meta: _91id_931QiwHLCbEyMeta || {},
    alias: _91id_931QiwHLCbEyMeta?.alias || [],
    redirect: _91id_931QiwHLCbEyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/business-admin/business/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z4cWhuArYOMeta?.name ?? "dashboard-business-admin-coupon-detail-id",
    path: _91id_93Z4cWhuArYOMeta?.path ?? "/dashboard/business-admin/coupon/detail/:id()",
    meta: _91id_93Z4cWhuArYOMeta || {},
    alias: _91id_93Z4cWhuArYOMeta?.alias || [],
    redirect: _91id_93Z4cWhuArYOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/business-admin/coupon/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93wkTSDF4X64Meta?.name ?? "dashboard-business-admin-coupon-edit-id",
    path: _91id_93wkTSDF4X64Meta?.path ?? "/dashboard/business-admin/coupon/edit/:id()",
    meta: _91id_93wkTSDF4X64Meta || {},
    alias: _91id_93wkTSDF4X64Meta?.alias || [],
    redirect: _91id_93wkTSDF4X64Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/business-admin/coupon/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: listtbLEWrQH38Meta?.name ?? "dashboard-business-admin-coupon-list",
    path: listtbLEWrQH38Meta?.path ?? "/dashboard/business-admin/coupon/list",
    meta: listtbLEWrQH38Meta || {},
    alias: listtbLEWrQH38Meta?.alias || [],
    redirect: listtbLEWrQH38Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/business-admin/coupon/list.vue").then(m => m.default || m)
  },
  {
    name: newVIw6LUw36bMeta?.name ?? "dashboard-business-admin-coupon-new",
    path: newVIw6LUw36bMeta?.path ?? "/dashboard/business-admin/coupon/new",
    meta: newVIw6LUw36bMeta || {},
    alias: newVIw6LUw36bMeta?.alias || [],
    redirect: newVIw6LUw36bMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/business-admin/coupon/new.vue").then(m => m.default || m)
  },
  {
    name: redeemtyrNlToC5uMeta?.name ?? "dashboard-business-admin-coupon-redeem",
    path: redeemtyrNlToC5uMeta?.path ?? "/dashboard/business-admin/coupon/redeem",
    meta: redeemtyrNlToC5uMeta || {},
    alias: redeemtyrNlToC5uMeta?.alias || [],
    redirect: redeemtyrNlToC5uMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/business-admin/coupon/redeem.vue").then(m => m.default || m)
  },
  {
    name: _91id_93AsX1Aa2UIxMeta?.name ?? "dashboard-business-admin-coupon-validate-id",
    path: _91id_93AsX1Aa2UIxMeta?.path ?? "/dashboard/business-admin/coupon/validate/:id()",
    meta: _91id_93AsX1Aa2UIxMeta || {},
    alias: _91id_93AsX1Aa2UIxMeta?.alias || [],
    redirect: _91id_93AsX1Aa2UIxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/business-admin/coupon/validate/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRIIpWdUktDMeta?.name ?? "dashboard",
    path: indexRIIpWdUktDMeta?.path ?? "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    alias: indexRIIpWdUktDMeta?.alias || [],
    redirect: indexRIIpWdUktDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: profile9R2UgaCjXxMeta?.name ?? "dashboard-profile",
    path: profile9R2UgaCjXxMeta?.path ?? "/dashboard/profile",
    meta: profile9R2UgaCjXxMeta || {},
    alias: profile9R2UgaCjXxMeta?.alias || [],
    redirect: profile9R2UgaCjXxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard/profile.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934Sh6rbj1csMeta?.name ?? "web-comercio-id",
    path: _91id_934Sh6rbj1csMeta?.path ?? "/web/comercio/:id()",
    meta: _91id_934Sh6rbj1csMeta || {},
    alias: _91id_934Sh6rbj1csMeta?.alias || [],
    redirect: _91id_934Sh6rbj1csMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/web/comercio/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kAm74EoMDgMeta?.name ?? "web-cupon-version-imprimible-id",
    path: _91id_93kAm74EoMDgMeta?.path ?? "/web/cupon/version-imprimible/:id()",
    meta: _91id_93kAm74EoMDgMeta || {},
    alias: _91id_93kAm74EoMDgMeta?.alias || [],
    redirect: _91id_93kAm74EoMDgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/web/cupon/version-imprimible/[id].vue").then(m => m.default || m)
  },
  {
    name: ppJgK2NRIUsZMeta?.name ?? "web-pp",
    path: ppJgK2NRIUsZMeta?.path ?? "/web/pp",
    meta: ppJgK2NRIUsZMeta || {},
    alias: ppJgK2NRIUsZMeta?.alias || [],
    redirect: ppJgK2NRIUsZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/web/pp.vue").then(m => m.default || m)
  },
  {
    name: registro_45comerciodvnWHRICCEMeta?.name ?? "web-registro-comercio",
    path: registro_45comerciodvnWHRICCEMeta?.path ?? "/web/registro-comercio",
    meta: registro_45comerciodvnWHRICCEMeta || {},
    alias: registro_45comerciodvnWHRICCEMeta?.alias || [],
    redirect: registro_45comerciodvnWHRICCEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/web/registro-comercio.vue").then(m => m.default || m)
  }
]